import cloneDeep from 'lodash.clonedeep';
import type { FetchError } from 'ofetch';

import { useRouteQuery } from '@vueuse/router';

import { EAbortError, useAbortController } from '~coreComposables/abort-controller.composable';

import { useFilterStore } from '@Store/filter.module';

import { searchItemsService } from '@Services/filter.service';

import { formatDateISO } from '~coreUtils/date';

import {
  EFilterFlags,
  EFilterSeparator,
  EFilterTypes,
  type IAdditionalSingleFilterValue,
  type TQueryWithFilters,
  type IRequest,
  type IAggregationCount,
  type IFilterResponse,
  type IAdditionalFilter
} from '@Models/Item/filter.model';

const initialRequestLimit = 30;

export const initialRequest: IRequest = {
  aggregations: [],
  termFilters: [],
  numberFilters: [],
  dateRangeFilters: [],
  fullTextFilters: [],
  limit: initialRequestLimit,
  orderBy: 'DESC',
  orderByField: '_score',
  offset: 0
};

const state = reactive<{
  accordionModel: IAdditionalFilter[];
  checkboxDetails: Record<string, IAggregationCount[]>;
  filters: {
    checkboxValue: IAdditionalSingleFilterValue[];
    lowerBound: Record<string, any>;
    upperBound: Record<string, any>;
    allRangeFilters: IAdditionalSingleFilterValue[];
  };
  loading: boolean;
  request: IRequest;
}>({
  accordionModel: [],
  checkboxDetails: {},
  filters: {
    // dane z input'ów w parametrach szczegółówych
    checkboxValue: [],
    lowerBound: {},
    upperBound: {},
    allRangeFilters: []
  },
  loading: false,
  request: cloneDeep(initialRequest)
});

export function useFilter() {
  const { accordionModel, checkboxDetails, filters, loading, request } = toRefs(state);

  const { abortRequest, registerAbort } = useAbortController();

  const filterStore = useFilterStore();

  const mainCategory = useRouteQuery('mainCategory', '', { transform: String, mode: 'push' });

  const subCategory = useRouteQuery('subCategory', '', { transform: String, mode: 'push' });

  const notes = useRouteQuery('notes', '', { transform: String, mode: 'push' });

  const city = useRouteQuery('city', '', { transform: String, mode: 'push' });

  const isMarginRequired = useRouteQuery('isMarginRequired', null, {
    transform: (value) => {
      if (value === 'false') return false;

      if (value === 'true') return true;
    },
    mode: 'push'
  });

  const openingValue_lowerBound = useRouteQuery('openingValue_lowerBound', '', { transform: String, mode: 'push' });

  const openingValue_upperBound = useRouteQuery('openingValue_upperBound', '', { transform: String, mode: 'push' });

  const startAuctionAt_upperBound = useRouteQuery('startAuctionAt_upperBound', null, {
    transform: (value) => {
      return transformDate(value);
    },
    mode: 'push'
  });

  const startAuctionAt_lowerBound = useRouteQuery('startAuctionAt_lowerBound', null, {
    transform: (value) => {
      return transformDate(value);
    },
    mode: 'push'
  });

  const sort = useRouteQuery('sort', `${EFilterFlags.SCORE}${EFilterSeparator.SEPARATOR}${EFilterFlags.DESC}`, {
    transform: String,
    mode: 'push'
  });

  const limit = useRouteQuery('limit', initialRequestLimit, { transform: Number, mode: 'push' });

  const offset = useRouteQuery('offset', 0, { transform: Number, mode: 'push' });

  const pageNumber = computed(() => offset.value / limit.value + 1);

  const transformDate = (value: null | Date) => {
    if (!value) return;
    return new Date(value);
  };

  const keyTable: { [key: string]: EFilterTypes } = {
    mainCategory: EFilterTypes.TERM_FILTERS,
    subCategory: EFilterTypes.TERM_FILTERS,
    notes: EFilterTypes.QUERY,
    city: EFilterTypes.FULL_TEXT_FILTERS,
    isMarginRequired: EFilterTypes.TERM_FILTERS,
    openingValue_lowerBound: EFilterTypes.NUMBER_FILTERS,
    openingValue_upperBound: EFilterTypes.NUMBER_FILTERS,
    openingValue: EFilterTypes.NUMBER_FILTERS,
    startAuctionAt_upperBound: EFilterTypes.DATE_RANGE_FILTERS,
    startAuctionAt_lowerBound: EFilterTypes.DATE_RANGE_FILTERS,
    startAuctionAt: EFilterTypes.DATE_RANGE_FILTERS,
    offset: EFilterTypes.OFFSET
  };

  const setRequestSection = (key: string, data: string | string[], valueName?: string) => {
    const requestKey = keyTable[key];

    if (request.value[requestKey]) {
      // @ts-expect-error
      request.value[requestKey] = [
        // @ts-expect-error
        ...request.value[requestKey],
        {
          field: key,
          [valueName || 'value']: data
        }
      ];
    } else {
      // @ts-expect-error
      request.value[requestKey] = [
        {
          field: key,
          [valueName || 'value']: data
        }
      ];
    }
  };

  const createFilters = async (query: TQueryWithFilters) => {
    for (const key in query) {
      const requestKey = keyTable[key];

      if (key === EFilterFlags.NOTES) {
        request.value[requestKey] = query[key] as never;
      } else if (requestKey === EFilterTypes.NUMBER_FILTERS) {
        const split = key.split('_');
        setRequestSection(split[0], query[key], split[1]);
      } else if (requestKey === EFilterTypes.FULL_TEXT_FILTERS) {
        setRequestSection(key, query[key]);
      } else if (requestKey === EFilterTypes.DATE_RANGE_FILTERS) {
        const split = key.split('_');
        const value = formatDateISO(new Date(query[key]));
        setRequestSection(split[0], value, split[1]);
      } else if (key === EFilterFlags.SORT) {
        const params = query[key].split(EFilterSeparator.SEPARATOR);
        request.value[EFilterTypes.ORDER_BY] = params[1];
        request.value[EFilterTypes.ORDER_BY_FIELD] = params[0];
      } else if (key === EFilterTypes.LIMIT || key === EFilterTypes.OFFSET) {
        request.value[key] = Number(query[key]);
      } else {
        setRequestSection(key, [query[key]]);
      }
    }

    if (!request.value[EFilterTypes.TERM_FILTERS]) request.value[EFilterTypes.TERM_FILTERS] = [];
    if (!request.value[EFilterTypes.NUMBER_FILTERS]) request.value[EFilterTypes.NUMBER_FILTERS] = [];
  };

  const createObjectForSearching = async (
    query: TQueryWithFilters,
    additionalFilters?: IAdditionalSingleFilterValue[]
  ) => {
    request.value = cloneDeep(initialRequest);
    await createFilters(query);

    if (additionalFilters?.length) {
      const aggregations = createAdditionalFilter(additionalFilters);
      await putAdditionalFilterInRequest(aggregations);
    }

    await fetchItems();
  };

  const createAdditionalFilter = (additionalFilters: IAdditionalSingleFilterValue[]) => {
    let aggregations: any = {};

    additionalFilters.forEach((singleFilter) => {
      const key = singleFilter.key;
      const nodeName = singleFilter.nodeName;
      const valueFilter = singleFilter.value;

      if (!Object.keys(aggregations).length) {
        if (key === EFilterTypes.TERM_FILTERS) {
          aggregations = {
            [key]: {
              [nodeName]: [valueFilter]
            }
          };
        } else {
          aggregations = {
            [key]: {
              [`${nodeName}${EFilterSeparator.SEPARATOR}${singleFilter.valueType}`]: valueFilter
            }
          };
        }
      } else {
        if (key === EFilterTypes.TERM_FILTERS) {
          const actualAggregationsValue = aggregations[key][nodeName] || [];
          aggregations[key][nodeName] = [...actualAggregationsValue, valueFilter];
        } else {
          if (!aggregations[key]) aggregations[key] = {};

          aggregations[key][`${nodeName}${EFilterSeparator.SEPARATOR}${singleFilter.valueType}`] = valueFilter;
        }
      }
    });

    return aggregations;
  };

  //dodanie dodatkowych filtrów do głównego requestu
  const putAdditionalFilterInRequest = async (aggregations: any) => {
    if (Object.keys(aggregations).length) {
      if (aggregations?.termFilters) {
        for (let key in aggregations.termFilters) {
          request.value.termFilters = [
            ...request.value.termFilters,
            {
              field: key,
              value: aggregations.termFilters[key]
            }
          ];
        }
      }

      if (aggregations?.numberFilters) {
        for (const key in aggregations.numberFilters) {
          const labels = key.split(EFilterSeparator.SEPARATOR);
          request.value.numberFilters = [
            ...(request.value.numberFilters || []),
            {
              field: labels[0],
              [labels[1]]: aggregations.numberFilters[key]
            }
          ];
        }
      }
    }
  };

  const fetchItems = async (paramNodeName?: string) => {
    try {
      // abort request if it's already running
      abortRequest();

      // register new abort signal
      const signal = registerAbort();

      const requestData = cloneDeep(request.value);

      if (paramNodeName) {
        // wyszukiwanie parametrów szczegółówych
        const response = await searchItemsService({ ...requestData, aggregations: [paramNodeName] }, signal);
        setCheckboxDetails(response);
      } else if (accordionModel.value.length) {
        loading.value = true;
        // wyszukiwanie z parametrami szczegółowymi
        const response = await searchItemsService(
          { ...requestData, aggregations: accordionModel.value.map((el) => el.nodeName) },
          signal
        );
        setCheckboxDetails(response);
        filterStore.setItems(response.items, response.count);
        loading.value = false;
      } else {
        // wyszukiwanie przedmiotów
        loading.value = true;
        const response = await searchItemsService(requestData, signal);
        setCheckboxDetails(response);
        filterStore.setItems(response.items, response.count);
        loading.value = false;
      }
    } catch (err: unknown) {
      const error = err as FetchError;
      if (error.cause !== EAbortError.ABORT_ERROR) {
        filterStore.setItems([]);
        if (!paramNodeName) loading.value = false;
      }
    }
  };

  function setCheckboxDetails(response?: IFilterResponse) {
    const aggregations = response?.aggregations || [];

    if (aggregations.length) {
      aggregations.forEach((aggregation) => {
        if (!checkboxDetails.value[aggregation.name]) checkboxDetails.value[aggregation.name] = [];
        checkboxDetails.value[aggregation.name] = aggregation.counts;
      });
    } else {
      clearCheckboxDetails();
    }
  }

  function calculateOffset(pageNumber: number) {
    if (pageNumber === 1) return 0;

    return (pageNumber - 1) * request.value.limit;
  }

  // scroll to top of the list
  function scrollToTop() {
    document.querySelector('#item-list-container')?.scrollIntoView({ behavior: 'smooth' });
  }

  async function onChangePage(pageNumber: number) {
    scrollToTop();

    offset.value = calculateOffset(pageNumber);
  }

  function clearCheckboxDetails() {
    checkboxDetails.value = {};
    filters.value.checkboxValue = [];
  }

  function clearAllFilters() {
    mainCategory.value = '';
    subCategory.value = '';
    notes.value = '';
    city.value = '';
    isMarginRequired.value = undefined;
    openingValue_lowerBound.value = '';
    openingValue_upperBound.value = '';
    startAuctionAt_lowerBound.value = undefined;
    startAuctionAt_upperBound.value = undefined;

    clearCheckboxDetails();

    filters.value.lowerBound = {};
    filters.value.upperBound = {};
    filters.value.allRangeFilters = [];

    accordionModel.value = [];

    request.value = cloneDeep(initialRequest);

    scrollToTop();
  }

  return {
    accordionModel,
    checkboxDetails,
    city,
    filters,
    initialRequestLimit,
    isMarginRequired,
    limit,
    loading,
    mainCategory,
    notes,
    openingValue_lowerBound,
    openingValue_upperBound,
    pageNumber,
    sort,
    startAuctionAt_upperBound,
    startAuctionAt_lowerBound,
    subCategory,
    clearAllFilters,
    clearCheckboxDetails,
    createObjectForSearching,
    fetchItems,
    onChangePage
  };
}
