import { defineStore } from 'pinia';
import type { IFilterStore } from '@Models/Item/filter.model';
import type { IItem } from '@Models/filters/item.model';

export const useFilterStore = defineStore('filters', {
  state: (): IFilterStore => ({
    count: null,
    items: []
  }),
  actions: {
    setItems(data: IItem[], count?: number) {
      this.items = data;
      this.count = count ?? null;
    }
  },
  getters: {
    getItems: (state) => state.items,
    getCount: (state) => state.count
  }
});
